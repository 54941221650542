@tailwind base;
@tailwind components;
@tailwind utilities;


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@font-face {
  font-family: 'Scale';
  src: url('../src/Utils/Fonts/Fontspring-DEMO-afragus-light.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Scale';
  src: url('../src/Utils/Fonts/Fontspring-DEMO-afragus-regular.otf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Scale';
  src: url('../src/Utils/Fonts/Fontspring-DEMO-afragus-semibold.otf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Scale';
  src: url('../src/Utils/Fonts/Fontspring-DEMO-afragus-bold.otf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Scale';
  src: url('../src/Utils/Fonts/Fontspring-DEMO-afragus-black.otf');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Scale';
  src: url('../src/Utils/Fonts/Fontspring-DEMO-afragus-extrabold.otf');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Scale';
  src: url('../src/Utils/Fonts/Fontspring-DEMO-afragus-extralight.otf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Luxe-uno';
  src: url('../src/Utils/Fonts/LuxeUno-Light.otf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Luxe-uno';
  src: url('../src/Utils/Fonts/LuxeUno-Regular.otf');
  font-weight: 400;
  font-style: normal;
}


@font-face {
  font-family: 'Luxe-uno';
  src: url('../src/Utils/Fonts/LuxeUno-SemiBold.otf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Luxe-uno';
  src: url('../src/Utils/Fonts/LuxeUno-Bold.otf');
  font-weight: 800;
  font-style: normal;
}

&::-webkit-scrollbar-thumb:horizontal {
  display: none;
}


body {
  margin: 0;
  font-family: 'Manrope', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #141414;
}

.header {
  z-index: 99999;
}


.font-scale {
  font-family: 'Scale', sans-serif !important;
}

.font-manrope {
  font-family: 'Manrope', sans-serif !important;
}


.font-luxe {
  font-family: 'Luxe-uno', sans-serif !important;
}


.progress {
  position: fixed;
  bottom: 20px;
  transform: rotate(-90deg);
  background-color: #FF402D;
  z-index: 10;
  width: 100%;
}


.privacy-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #173433;
  z-index: 10000;
}


.button-header {
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  top: 18px;
  left: 15px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
}

.swiper-button-prev {
  position: absolute !important;
  left: 450px !important;
  top: 580px !important;
  color: white !important;
  background-color: #1F524D !important;
  width: 50px !important;
  height: 50px !important;
  border-radius: 50% !important;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 20px !important;
}

.swiper-button-next {
  position: absolute !important;
  bottom: 0px !important;
  top: 580px !important;
  color: white !important;
  font-size: 10px !important;
  background-color: #1F524D !important;
  width: 50px !important;
  height: 50px !important;
  border-radius: 50% !important;
}


